<template>
  <div>
    <!--begin::Card-->
    <div
      class="card card-custom wave wave-animate-fast wave-danger mb-2 mb-lg-0"
    >
      <div class="card-body">
        <!--begin::Details-->
        <div class="d-flex mb-9">
          <!--begin: Pic-->
          <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            <div class="symbol symbol-50 symbol-lg-120">
              <img
                alt="Pic"
                src="https://play-lh.googleusercontent.com/GMnC3SHYBjKXtNVz4lhZ_je3e_OdL7ZHd6FAFx8o_2gFHvptKAHWV0YpzKZOKsj4Uw=s180-rw"
              />
            </div>

            <div class="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
              <span
                class="font-size-h3 symbol-label font-weight-boldest"
              ></span>
            </div>
          </div>
          <!--end::Pic-->

          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex mr-3">
                <a
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.dolmusdriving"
                  target="_blank"
                  class="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >Minibus Bus Transport Driver</a
                >
                <i class="flaticon2-correct text-success font-size-h5"></i>
              </div>
            </div>
            <!--end::Title-->

            <!--begin::Content-->
            <div class="d-flex flex-wrap justify-content-between mt-1">
              <div class="d-flex flex-column flex-grow-1 pr-8">
                <div class="d-flex flex-wrap my-2">
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"/></span
                    >support@beyazgames.com</a
                  >
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg
                        src="media/svg/icons/General/Lock.svg"/></span
                    >Simulation
                  </a>
                  <a
                    href="#"
                    class="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    ><span class="svg-icon svg-icon-md svg-icon-gray-500 mr-1"
                      ><inline-svg src="media/svg/icons/Map/Marker2.svg"/></span
                    >Android, iOS</a
                  >
                </div>

                <span class="font-weight-bold text-dark-50"
                  >You can enjoy driving a bus minibus in the city.</span
                >
              </div>

              <div class="my-lg-0 my-1">
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.beyazgames.dolmusdriving"
                  class="btn font-weight-bolder text-uppercase mr-2"
                  ><img
                    width="128"
                    height="40"
                    src="media/logos/store_android.png"
                /></a>
                <a href="#" class="btn font-weight-bolder text-uppercase mr-2"
                  ><img width="128" height="40" src="media/logos/app-store.png"
                /></a>
              </div>
            </div>
            <!--end::Content-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->

        <div class="separator separator-solid"></div>

        <!--begin::Items-->
        <div class="d-flex align-items-center flex-wrap mt-8">
          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-pie-chart display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Downloads</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span
                >300,000</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-confetti display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column text-dark-75">
              <span class="font-weight-bolder font-size-sm">Stars</span>
              <span class="font-weight-bolder font-size-h5"
                ><span class="text-dark-50 font-weight-bold">+</span>1000</span
              >
            </div>
          </div>
          <!--end::Item-->

          <!--begin::Item-->
          <div class="d-flex align-items-center flex-lg-fill mr-5 mb-2">
            <span class="mr-4">
              <i
                class="flaticon-chat-1 display-4 text-muted font-weight-bold"
              ></i>
            </span>
            <div class="d-flex flex-column">
              <span class="text-dark-75 font-weight-bolder font-size-sm"
                >500+ Comments</span
              >
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.beyazgames.dolmusdriving&showAllReviews=true"
                class="text-primary font-weight-bolder"
                >View</a
              >
            </div>
          </div>
          <!--end::Item-->
        </div>
        <!--begin::Items-->
      </div>
    </div>
    <!--end::Card-->

    <div class="card card-custom mb-2 mb-lg-0">
      <div class="card-body">
        <div class="why-title home-section-title">
          <h3 class="text-center">How We Work</h3>
        </div>

        <div class="row">
          <div class="col-lg-4">
            <div class="kt-portlet kt-iconbox">
              <div class="kt-portlet__body">
                <div class="d-flex align-items-center p-5">
                  <div class="mr-6">
                    <span class="svg-icon svg-icon-primary svg-icon-4x">
                      <inline-svg src="media/svg/icons/How/code.svg" />
                    </span>
                  </div>

                  <div class="iconbox__desc">
                    <h3 class="iconbox__title">Proactive</h3>
                    <div class="iconbox__content">
                      Take iniative by independently identifying and achieving
                      what needs to be done.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="kt-portlet kt-iconbox">
              <div class="kt-portlet__body">
                <div class="d-flex align-items-center p-5">
                  <div class="mr-6">
                    <span class="svg-icon svg-icon-primary svg-icon-4x">
                      <inline-svg src="media/svg/icons/How/support.svg" />
                    </span>
                  </div>

                  <div class="iconbox__desc">
                    <h3 class="iconbox__title">One Team</h3>
                    <div class="iconbox__content">
                      Keep in mind that your work has an impact that goes beyond
                      you and your team.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="kt-portlet kt-iconbox">
              <div class="kt-portlet__body">
                <div class="d-flex align-items-center p-5">
                  <div class="mr-6">
                    <span class="svg-icon svg-icon-primary svg-icon-4x">
                      <inline-svg src="media/svg/icons/How/okey.svg" />
                    </span>
                  </div>

                  <div class="kt-iconbox__desc">
                    <h3 class="kt-iconbox__title">Aim High</h3>
                    <div class="kt-iconbox__content">
                      Set higher standards and follow them through.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="kt-portlet mb-lg-0 kt-iconbox">
              <div class="kt-portlet__body">
                <div class="d-flex align-items-center p-5">
                  <div class="mr-6">
                    <span class="svg-icon svg-icon-primary svg-icon-4x">
                      <inline-svg src="media/svg/icons/How/okey2.svg" />
                    </span>
                  </div>

                  <div class="kt-iconbox__desc">
                    <h3 class="kt-iconbox__title">Move Fast</h3>
                    <div class="kt-iconbox__content">
                      Move fast and get things done.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="kt-portlet mb-lg-0 kt-iconbox">
              <div class="kt-portlet__body">
                <div class="d-flex align-items-center p-5">
                  <div class="mr-6">
                    <span class="svg-icon svg-icon-primary svg-icon-4x">
                      <inline-svg src="media/svg/icons/How/lock.svg" />
                    </span>
                  </div>

                  <div class="kt-iconbox__desc">
                    <h3 class="kt-iconbox__title">Logical</h3>
                    <div class="kt-iconbox__content">
                      Form and express your views in a logical manner.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="kt-portlet mb-lg-0 kt-iconbox">
              <div class="kt-portlet__body">
                <div class="d-flex align-items-center p-5">
                  <div class="mr-6">
                    <span class="svg-icon svg-icon-primary svg-icon-4x">
                      <inline-svg src="media/svg/icons/How/update.svg" />
                    </span>
                  </div>

                  <div class="kt-iconbox__desc">
                    <h3 class="kt-iconbox__title">Prioritize</h3>
                    <div class="kt-iconbox__content">
                      Focus on what really matters first.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container py-8">
        <div class="row">
          <div class="col-lg-6">
            <div
              class="card card-custom wave wave-animate-slow wave-primary mb-8 mb-lg-0"
            >
              <div class="card-body">
                <div class="d-flex align-items-center p-5">
                  <div class="mr-6">
                    <span class="svg-icon svg-icon-primary svg-icon-4x">
                      <inline-svg src="media/svg/icons/Home/Mirror.svg" />
                    </span>
                  </div>
                  <router-link :to="'support-center'">
                    <div class="d-flex flex-column">
                      <a
                        href="support-center"
                        class="text-dark text-hover-warning font-weight-bold font-size-h4 mb-3"
                        >Get Started</a
                      >
                      <div class="text-dark-75">Base FAQ Questions</div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div
              class="card card-custom wave wave-animate-slow wave-danger mb-8 mb-lg-0"
            >
              <div class="card-body">
                <div class="d-flex align-items-center p-5">
                  <div class="mr-6">
                    <span class="svg-icon svg-icon-danger svg-icon-4x">
                      <inline-svg
                        src="media/svg/icons/General/Thunder-move.svg"
                      />
                    </span>
                  </div>
                  <div class="d-flex flex-column">
                    <a
                      href="mailto:support@beyazgames.com"
                      class="text-dark text-hover-warning font-weight-bold font-size-h4 mb-3"
                      >Fast Contact</a
                    >
                    <div class="text-dark-75">support@beyazgames.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div
        class="cloud-wrapper py-2"
        style="background-image: url(media/bg/cloud-banner.jpg)"
      >
        <div class="container grid__item grid__item--fluid">
          <div class="row">
            <div class="col-md-8 d-flex align-items-center pr-md-5">
              <div
                class="text-dark text-hover-warning font-weight-bold font-size-h4 mb-3"
              >
                <h2 class="h1 kt-font-bolder text-white mb-6 cloud-title">
                  Who we are
                </h2>
                <p class="kt-font-bold text-white-50 mb-5 cloud-desc">
                  We are a game studio located in Turkey, operating
                  independently. Our goal is to develop and self-publish games
                  with best gaming experience and high quality production values
                  by small agile development teams.
                </p>
              </div>
            </div>
            <div class="col-md-4 text-center text-md-right py-md-5">
              <img
                class="img-fluid d-none d-md-inline-block"
                style="width: 500px"
                src="media/bg/cloud.png"
                alt="Cloud"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "dashboard",
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Beyaz Games" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
